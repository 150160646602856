import { useEffect } from 'react';

function useInterval(callback, delay) {
  useEffect(() => {
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => callback(), delay);

    return () => clearInterval(id);
  }, [callback, delay]);
}

export default useInterval;
