export const iess = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/iess_y8lsit.png',
  title: 'Instituto Ecuatoriano de Seguridad Social',
  url: 'https://www.iess.gob.ec/',
};

export const nestle = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/nestle_han8oo.png',
  title: 'Nestle Ecuador',
  url: 'https://www.nestle.com.ec/',
};

export const soltein = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/soltein_ameuwh.png',
  title: 'Soltein',
  url: 'https://www.soltein.net/',
};

export const soyHincha = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/soy-hincha_ukafpv.png',
  title: 'Soy Hincha',
  url: 'https://www.soyhincha.com/',
};

export const udla = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/udla_uxfsf4.png',
  title: 'Universidad de las Américas',
  url: 'https://www.udla.edu.ec/',
};

export const modernaAlimentos = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/moderna-alimentos_ik0txc.png',
  title: 'Alimentos Moderna',
  url: 'https://www.moderna.com.ec/',
};

export const stackbuilders = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/stackbuilders_rtrxnf.png',
  title: 'Stack Builders',
  url: 'https://www.stackbuilders.com/',
};

export const technisys = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/technisys_y81vht.png',
  title: 'Stack Builders',
};

export const salica = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/salica_giba20.png',
  title: 'Stack Builders',
};

export const klever = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/klever_bcqx5y.png',
  title: 'Stack Builders',
};

export const procredit = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994100/partners/customers/banco-procredit_uutxxk.png',
  title: 'Banco ProCredit',
};

export const farmaenlace = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/farmaenlace_ojvihs.png',
  title: 'Stack Builders',
};

export const megaprofer = {
  logo: 'https://res.cloudinary.com/aibesorg/image/upload/v1644994099/partners/customers/megaprofer_s5bh4l.png',
  title: 'Megaprofer',
};
