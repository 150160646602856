import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import Image from 'next/image';
import Container from '@/components/Container';
import { withAuthenticatedUser } from '@/components/ProtectedPage';
import CustomerLogos, { customers } from '@/components/CustomerLogos';
import useInterval from '@/lib/useInterval';
import CredlyImage from '@/public/credly.webp';
import clsx from 'clsx';

const indexHeroCarouselInfo = [
  {
    key: 'official-scrum-trainer-001',
    alt: 'official-scrum-trainer-001',
    image:
      'https://res.cloudinary.com/aibesorg/image/upload/v1644994105/partners/trainer-home-001_zytvyh.png',
    color: 'bg-yellow-500',
  },
  {
    key: 'official-scrum-trainer-002',
    alt: 'official-scrum-trainer-002',
    image:
      'https://res.cloudinary.com/aibesorg/image/upload/v1644994104/partners/trainer-home-003_bugzfb.png',
    color: 'bg-red-500',
  },
  {
    key: 'official-scrum-trainer-003',
    alt: 'official-scrum-trainer-003',
    image:
      'https://res.cloudinary.com/aibesorg/image/upload/v1644994103/partners/trainer-home-002_geavqb.png',
    color: 'bg-emerald-500',
  },
];

function IndexHeroCarouselClouds({ show, itm, zIndex }) {
  return (
    <Transition
      show={show}
      enter="transition-all duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      unmount={false}
      appear={true}
    >
      <div className="absolute" style={{ zIndex }}>
        <svg
          width="601"
          height="592"
          viewBox="0 0 601 592"
          fill="none"
          className={clsx(
            'absolute top-0 right-0 mt-20 mr-20 blur-3xl fill-current',
            itm.color
          )}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M382.321 0.0323192C213.172 2.52387 115.569 68.8961 87.9103 101.771C-56.0459 241.402 -10.0599 194.166 130.897 238.807C271.854 283.447 139.894 302.653 271.854 506.13C403.814 709.607 437.304 506.13 547.77 362.865C658.237 219.601 593.756 -3.08213 382.321 0.0323192Z" />
        </svg>
      </div>
    </Transition>
  );
}

IndexHeroCarouselClouds.defaultProps = {
  show: false,
  zIndex: 0,
};

IndexHeroCarouselClouds.propTypes = {
  show: PropTypes.bool,
  zIndex: PropTypes.number,
  itm: PropTypes.shape({
    image: PropTypes.object,
    color: PropTypes.string,
  }).isRequired,
};

function IndexHeroCarouselImage({ show, alt, image, zIndex }) {
  return (
    <Transition
      show={show}
      enter="transition-all duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      unmount={false}
      appear={true}
    >
      <div
        className="absolute inset-0 flex items-center justify-center pb-20"
        style={{ zIndex }}
      >
        <Image width={400} height={500} alt={alt} src={image} quality={100} />
      </div>
    </Transition>
  );
}

IndexHeroCarouselImage.defaultProps = {
  show: false,
  zIndex: 0,
};

IndexHeroCarouselImage.propTypes = {
  show: PropTypes.bool,
  zIndex: PropTypes.number,
  image: PropTypes.object.isRequired,
};

const CAROUSEL_INTERVAL = 5000;

function IndexHeroCarousel() {
  const [currentImageIndex, setNextImageIndex] = useState(0);

  const [interval, setInterval] = useState(CAROUSEL_INTERVAL);

  useInterval(() => {
    setNextImageIndex((prev) => {
      let next = prev + 1;
      if (next > indexHeroCarouselInfo.length - 1) {
        next = 0;
      }
      return next;
    });
  }, interval);

  useEffect(() => {
    const handler = function () {
      if (document.hidden) {
        setInterval(null);
      } else {
        setInterval(CAROUSEL_INTERVAL);
      }
    };
    document.addEventListener('visibilitychange', handler);
    return () => document.removeEventListener('visibilitychange', handler);
  }, []);

  return (
    <div className="relative ml-20 inline-flex">
      <svg
        width="800"
        height="800"
        viewBox="0 0 1067 1067"
        fill="none"
        className="relative z-10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M533.5 1066.5C827.868 1066.5 1066.5 827.868 1066.5 533.5C1066.5 239.132 827.868 0.5 533.5 0.5C239.132 0.5 0.5 239.132 0.5 533.5C0.5 827.868 239.132 1066.5 533.5 1066.5Z"
          fill="white"
          fillOpacity="0.2"
          stroke="white"
        />
        <path
          d="M533 969.5C774.072 969.5 969.5 774.072 969.5 533C969.5 291.928 774.072 96.5 533 96.5C291.928 96.5 96.5 291.928 96.5 533C96.5 774.072 291.928 969.5 533 969.5Z"
          fill="white"
          fillOpacity="0.5"
          stroke="white"
        />
        <path
          d="M533 877C722.986 877 877 722.986 877 533C877 343.014 722.986 189 533 189C343.014 189 189 343.014 189 533C189 722.986 343.014 877 533 877Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M668.244 956.489C672.245 956.489 675.489 953.245 675.489 949.244C675.489 945.243 672.245 942 668.244 942C664.243 942 661 945.243 661 949.244C661 953.245 664.243 956.489 668.244 956.489Z"
          fill="#61D7AC"
        />
        <path
          d="M668.244 960.75C674.599 960.75 679.75 955.599 679.75 949.244C679.75 942.89 674.599 937.739 668.244 937.739C661.89 937.739 656.739 942.89 656.739 949.244C656.739 955.599 661.89 960.75 668.244 960.75Z"
          stroke="#61D7AC"
          strokeOpacity="0.1"
          strokeWidth="8.52288"
        />
        <path
          d="M208.244 666.489C212.245 666.489 215.489 663.245 215.489 659.244C215.489 655.243 212.245 652 208.244 652C204.243 652 201 655.243 201 659.244C201 663.245 204.243 666.489 208.244 666.489Z"
          fill="#22C55E"
        />
        <path
          d="M208.244 670.75C214.599 670.75 219.75 665.599 219.75 659.244C219.75 652.89 214.599 647.739 208.244 647.739C201.89 647.739 196.739 652.89 196.739 659.244C196.739 665.599 201.89 670.75 208.244 670.75Z"
          stroke="#22C55E"
          strokeOpacity="0.1"
          strokeWidth="8.52288"
        />
        <path
          d="M392.244 123.489C396.245 123.489 399.489 120.245 399.489 116.244C399.489 112.243 396.245 109 392.244 109C388.243 109 385 112.243 385 116.244C385 120.245 388.243 123.489 392.244 123.489Z"
          fill="#22C55E"
        />
        <path
          d="M392.244 127.75C398.599 127.75 403.75 122.599 403.75 116.244C403.75 109.89 398.599 104.739 392.244 104.739C385.89 104.739 380.739 109.89 380.739 116.244C380.739 122.599 385.89 127.75 392.244 127.75Z"
          stroke="#22C55E"
          strokeOpacity="0.1"
          strokeWidth="8.52288"
        />
        <path
          d="M901.244 152.489C905.245 152.489 908.489 149.245 908.489 145.244C908.489 141.243 905.245 138 901.244 138C897.243 138 894 141.243 894 145.244C894 149.245 897.243 152.489 901.244 152.489Z"
          fill="#22C55E"
        />
        <path
          d="M901.244 156.75C907.599 156.75 912.75 151.599 912.75 145.244C912.75 138.89 907.599 133.739 901.244 133.739C894.89 133.739 889.739 138.89 889.739 145.244C889.739 151.599 894.89 156.75 901.244 156.75Z"
          stroke="#22C55E"
          strokeOpacity="0.1"
          strokeWidth="8.52288"
        />
      </svg>
      {indexHeroCarouselInfo.map((itm, idx) => (
        <IndexHeroCarouselClouds
          key={`${itm.key}-svg-shape`}
          show={currentImageIndex === idx}
          zIndex={idx}
          itm={itm}
        />
      ))}
      <div className="absolute inset-0 flex items-center justify-center z-20">
        {indexHeroCarouselInfo.map((itm, idx) => (
          <IndexHeroCarouselImage
            key={itm.key}
            show={currentImageIndex === idx}
            zIndex={idx}
            alt={itm.alt}
            image={itm.image}
          />
        ))}
      </div>
    </div>
  );
}

function IndexPage() {
  return (
    <div className="w-full flex flex-col">
      <div className="bg-green-100 bg-gradient-to-tl from-green-200 to-green-50 relative overflow-hidden">
        <Container className="py-10 relative z-20">
          <div className="py-10 lg:py-20">
            <div className="grid grid-cols-1 lg:grid-cols-12 relative z-10">
              <div className="space-y-6 lg:space-y-8 relative z-20 lg:col-span-6">
                <h2 className="font-semibold text-lg lg:text-xl text-green-500">
                  Comunidad de socios
                </h2>
                <div className="text-4xl lg:text-5xl xl:text-6xl">
                  <h1 className="leading-tight font-display font-extrabold tracking-tight">
                    Hagamos crecer juntos la gran familia que somos
                  </h1>
                </div>
                <div className="text-lg lg:text-xl lg:pr-20">
                  <p className="text-gray-800 leading-relaxed">
                    Como parte de nuestra comunidad global, con nuestros socios
                    trabajamos juntos para acelerar el impacto en la adopción de
                    la cultura ágil de nuestros clientes, mejorar sus
                    habilidades y obtener más y mejores resultados.
                  </p>
                </div>
                <div className="flex items-center lg:pt-4">
                  <a
                    className="inline-flex bg-green-500 text-white rounded-full px-6 py-2 lg:px-8 lg:py-3 lg:text-lg font-semibold hover:bg-green-600 focus:bg-green-600 focus:ring-4 focus:ring-green-600 focus:ring-opacity-20"
                    href="https://www.aibes.org/programa-de-socios/"
                    role="button"
                  >
                    Aplica al programa de socios
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute z-0 inset-0 pointer-events-none hidden md:grid grid-cols-12 items-center">
            <div className="col-span-6 col-start-6">
              <IndexHeroCarousel />
            </div>
          </div>
        </Container>
        <div className="w-2/4 h-2/4 bg-gradient-to-l from-green-200 to-transparent absolute right-0 top-0 z-0 blur-3xl mt-40" />
        <div className="w-2/6 h-2/6 bg-gradient-to-br from-green-200 to-white absolute right-0 bottom-0 z-0 blur-3xl mt-40" />
      </div>
      <div className="bg-white py-10 lg:py-20">
        <Container className="space-y-10">
          <h2 className="text-center text-lg lg:text-2xl font-semibold lg:font-medium tracking-tight">
            Con la confianza de empresas e instituciones reconocidas
          </h2>
          <CustomerLogos
            customers={[
              customers.udla,
              customers.iess,
              customers.procredit,
              customers.nestle,
              customers.soltein,
              customers.modernaAlimentos,
              customers.soyHincha,
              customers.farmaenlace,
              customers.klever,
              customers.stackbuilders,
              customers.technisys,
              customers.megaprofer,
            ]}
          />
          <div className="text-center">
            <a
              className="text-lg font-semibold text-green-500 hover:text-green-600 inline-flex items-center space-x-2"
              href="//aibes.org/clientes"
              role="button"
            >
              <span>Todos nuestros clientes</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-gray-300"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </Container>
      </div>
      <div
        className="py-10 lg:py-20 xl:py-32 text-white relative overflow-hidden"
        style={{ backgroundColor: '#005850' }}
      >
        <Container className="relative z-10">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-10 lg:gap-20">
            <div className="md:col-span-6 lg:col-span-7">
              <div className="flex flex-col space-y-6 lg:space-y-8">
                <div className="flex flex-col space-y-6">
                  <div className="text-3xl lg:text-5xl">
                    <h2 className="leading-tight font-semibold lg:font-bold">
                      Insignias digitales de certificaciones
                    </h2>
                  </div>
                  <p className="text-white text-opacity-80 text-lg lg:text-xl">
                    Todas nuestras certificaciones poseen insignias digitales en
                    la red de insignias digitales más grande del mundo.
                  </p>
                </div>
                <div>
                  <a
                    className="inline-flex rounded-full text-white font-medium lg:font-semibold border-2 border-white border-opacity-40 px-4 py-2 leading-4 hover:bg-white hover:text-gray-900 focus:bg-white focus:text-gray-900 focus:outline-none focus:ring-4 focus:ring-white focus:ring-opacity-20 hover:border-transparent focus:border-transparent"
                    href="https://www.credly.com/organizations/asociacion-iberoamericana-de-scrum/badges"
                    role="button"
                  >
                    Conoce nuestras insignias en Credly
                  </a>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 lg:col-span-5 flex items-center justify-center">
              <div className="p-10">
                <Image src={CredlyImage} alt="Credly" />
              </div>
            </div>
          </div>
        </Container>
        <div className="absolute top-0 bottom-0 right-0 w-2/4 transform -skew-y-12 flex flex-col items-end justify-end">
          <div className="w-2/6 h-28 bg-gradient-to-l from-white to-transparent opacity-10" />
          <div className="w-full h-28 bg-gradient-to-l from-white to-transparent opacity-10" />
          <div className="w-2/4 h-28 bg-gradient-to-l from-white to-transparent opacity-20" />
          <div className="w-3/5 h-28 bg-gradient-to-l from-white to-transparent opacity-10" />
          <div className="w-2/6 h-28 bg-gradient-to-l from-white to-transparent opacity-10" />
        </div>
      </div>
    </div>
  );
}

export default withAuthenticatedUser(IndexPage, {
  doNotRedirectToLogin: true,
});
