import PropTypes from 'prop-types';
import Image from 'next/image';
import Container from '@/components/Container';
export default function CustomerLogos({ customers }) {
  return (
    <Container className="lg:px-20">
      <div className="flex items-center justify-center">
        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-4">
          {customers.map(({ logo, title, url }, index) => (
            <div key={`customer-logo-${url}-${index}`}>
              <Image src={logo} alt={title} width={300} height={190} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

CustomerLogos.defaultProps = {
  customers: [],
};

CustomerLogos.propTypes = {
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      title: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};
